import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';
import { EventsHubService } from '../services/events-hub.service';
import { ILoginCredentials } from '../../shared/interfaces';
import { HttpClient } from '@angular/common/http';
import { ExceptionService } from './exception.service';
import { CONFIG } from '../../config';



@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private readonly loginPath = `${CONFIG.apiPath}/oauth/token`;

  constructor(
    private readonly http: HttpClient,
    private readonly exceptionService: ExceptionService,
    private readonly eventsHubService: EventsHubService,
  ) {
    this.unAuthorizedListener();
  }

  private unAuthorizedListener(): void {
    this.eventsHubService.httpUnauthorizedError$.subscribe(() => {
      this.eventsHubService.setLoggedIn(null);
    });
  }

  public login(formDataCredentials: ILoginCredentials): Observable<any> {
    let body = new URLSearchParams();
    body.set('grant_type', formDataCredentials.grant_type);
    body.set('client_id', formDataCredentials.client_id);
    body.set('client_secret', formDataCredentials.client_secret);
    body.set('username', formDataCredentials.username);
    body.set('password', formDataCredentials.password);
    return this.http.post(this.loginPath, body, { observe: 'response' }).pipe(timeout(CONFIG.httpTimeout),
      catchError(ex => this.exceptionService.catchBadResponse(ex)));
  }
}
